<template>
	<div class="">
		<div class="_w-max" style="background-color: #f7f7f7;">
			<img style="width: 100%;" :src="agent_info.issue_banner.url"/>
		</div>
		<div class="_w-max _pc-pad-x-280 _pc-pad-top-45 _pc-pad-bottom-65 mb-pad-x-15 mb-pad-bottom-20" style="background: #f7f7f7;">
			<breadcumb></breadcumb>
			<div class="_pc-mar-top-40 main mb-pad-x-15">
				<div class="main-top">
					<div class="main-top-left">
						<div class="title">{{list[listIndex].title}}</div>
						<div class="time">{{ $t('date') }}：{{list[listIndex].add_time}}</div>
					</div>
					<div class="main-top-right display-none" @click="fanhui">
						<img style="width: 16px;height: 16px;" src="~@/assets/image/hangda/fanhui1.png" alt="">
						<span>{{ $t('返回') }}</span>
					</div>
				</div>
				<div class="main-body">
					<div v-html="list[listIndex].content"></div>
				</div>
				<div class="main-footer">
					<div class="pre" @click="pre" v-if="listIndex>0" :title="$t('上一篇')+'：'+list[listIndex-1].title">
						<img style="width: 15px;height: 15px;" src="~@/assets/image/hangda/pre.png" alt="">
						<span>{{ $t('上一篇') }}</span>
					</div>		
					<div class="next" @click="next" v-if="listIndex+1<count" :title="$t('next')+'：'+list[listIndex+1].title">
						<span>{{ $t('next') }}</span>
						<img style="width: 15px;height: 15px;" src="~@/assets/image/hangda/next.png" alt="">
					</div>
				</div>
			</div>	
		</div>
	</div>
</template>

<script>
	import breadcumb from './components/breadcumb.vue';
	import { articleDetail, bannerList } from '@/api/jiyun';
	export default {
		components: { 
			breadcumb
		},
		data() {
			return {
				// metaInfo
				title: this.$t('page_title.question_detail'),
				
				list :[], 
				count:0,//list 总条数
				listIndex:0, //当前展示的详情下标
				question_id:'',	//详情id 
				IsShowPre:true,
				IsShowNext:true,
				article_id: 0,
				agent_info: {},
			}
		},
		metaInfo() {
			return {
				title: this.title, // set a title
			};
		},
		created() {
			this.article_id = this.$route.query.id;
			this.getList();
			this.bannerList();
		},
		methods: {
			// 代理banner
			bannerList(){
				let param = {
					domain_url: window.location.origin
				}
				bannerList(param).then((res)=>{
					this.agent_info = res.data;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			getList() {
				let param = {
					article_id: this.article_id,
					domain_url: window.location.origin
				}
				articleDetail(param).then((res)=>{
					this.list = res.data.list;
					this.count = res.data.count;
					this.listIndex = res.data.index;
				}).catch((err)=>{
					this.$Message.error(err.msg)
				})
			},
			pre() {
				// 1 第一条
				let first = 0;
				// 如果 当前展示的详情 <= 0不能继续点击上一页
				let nowIndex = this.listIndex;
				if(nowIndex <= first) {
					return false;
				}else {
					this.listIndex = nowIndex - 1;
				}
			},
			next() {
				// 最后一条
				let last = this.list.length;
				// 如果 当前展示的详情 >= 总条数 不能继续点击下一页
				let nowIndex = this.listIndex;
				if(nowIndex >= last) {
					return false;
				}else {
					this.listIndex = nowIndex + 1;
				}
			},
			fanhui(){
				this.$router.replace({
					path: '/question',
				})
			},
		}
	}
</script>

<style scoped>
	.main {
		width: 100%;
		background-color: #fff;
		padding: 0 35px;
	}
	.main-top {
		width: 100%;
		height: 160px;
		border-bottom: 1px solid rgba(153, 153, 153, 0.2);
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.main-top .main-top-left .title {
		font-size: 32px;
		color: #333333;
		font-weight: bold;
	}
	.main-top .main-top-left .time {
		margin-top: 18px;
		font-size: 18px;
		color: #999999;
	}
	.main-top .main-top-right {
		/* width: 194px; */
		padding: 0px 25px;
		height: 42px;
		text-align: center;
		line-height: 42px;
		color: #ffffff;
		background-color: #4284DC;
		border-radius: 14px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.main-top .main-top-right:hover {
		opacity: 0.6;
		cursor: pointer;
	}
	.main-top .main-top-right img {
		margin-right: 6px;
	}
	.main-body {
		padding: 50px 0px;
		font-size: 18px;
	}
	.main-footer {
		width: 100%;
		height: 75px;
		border-top: 1px solid rgba(153, 153, 153, 0.2);
		display: flex;
		align-items: center;
		/* justify-content: space-between; */
		position: relative;
	}
	.main-footer .pre {
		display: flex;
		align-items: center;
		color: #333333;
		font-size: 16px;
		position: absolute;
		left: 30px;
	}
	.main-footer .next {
		display: flex;
		align-items: center;
		color: #333333;
		font-size: 16px;
		position: absolute;
		right: 30px;
	}
	.main-footer .pre:hover {
		color: #4284DC;
		cursor: pointer;
		opacity: 0.4;
	}
	.main-footer .next:hover {
		color: #4284DC;
		cursor: pointer;
		opacity: 0.4;
	}
	.main-footer .pre img {
		margin-right: 7px;
	}
	.main-footer .next img {
		margin-left: 7px;
	}
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		input {
			outline: none;
		}
		.display-none {
			display: none !important;
		}
		.mb-display-block {
			display: block;
		}
		/* pad */
		.mb-pad-0 {
			padding: 0 !important;
		}
		.mb-pad-x-15 {
			padding-left: 0.9375rem !important;
			padding-right: 0.9375rem !important;
		}
		.mb-pad-x-25 {
			padding-left: 1.5625rem;
			padding-right: 1.5625rem;
		}
		.mb-pad-x-30 {
			padding-left: 1.875rem;
			padding-right: 1.875rem;
		}
		.mb-pad-y-15 {
			padding-top: 0.9375rem !important;
			padding-bottom: 0.9375rem !important;
		}
		.mb-pad-y-25 {
			padding-top: 1.5625rem;
			padding-bottom: 1.5625rem;
		}
		.mb-pad-y-30 {
			padding-top: 1.875rem;
			padding-bottom: 1.875rem;
		}
		.mb-pad-bottom-20 {
			padding-bottom: 1.25rem;
		}
		/* mar */
		.mb-mar-0 {
			margin: 0;
		}
		.mb-mar-top-30 {
			margin-top: 1.875rem;
		}
		.mb-mar-top-50 {
			margin-top: 3.125rem;
		}
		.mb-mar-right-15 {
			margin-right: 0.9375rem;
		}
		.mb-mar-y-15 {
			margin-top: 0.9375rem;
			margin-bottom: 0.9375rem;
		}
		.mb-mar-y-25 {
			margin-top: 1.5625rem;
			margin-bottom: 1.5625rem;
		}
		/* width */
		.mb-w-max {
			width: 100%;
		}
		.mb-w-70 {
			width: 4.375rem;
		}
		.mb-w-100 {
			width: 6.25rem;
		}
		.mb-w-18 {
			width: 1.125rem;
		}
		/* height */
		.mb-h-60 {
			height: 3.75rem;
		}
		/* font */
		.mb-font-15 {
			font-size: 0.9375rem !important;
		}
		.mb-font-20 {
			font-size: 1.25rem;
		}
		.mb-font-25 {
			font-size: 1.5625rem;
		}
		.mb-font-30 {
			font-size: 1.875rem;
		}
		.mb-font-35 {
			font-size: 2.1875rem;
		}
		
		.mb-text-center {
			text-align: center;
		}
		/* flex */
		.mb-flex-1 {
			flex: 1;
		}
		.mb-flex-2 {
			flex: 2;
		}
		.mb-flex-col-center-mid {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		}
		.mb-flex-row-mid {
			display: flex;
			flex-direction: row;
			align-items: center;
		}
		.mb-flex-shrink {
			flex-shrink: 0;
		}
		.mb-border-radius-10 {
			border-radius: 0.625rem;
		}
		.mb-border-radius-15 {
			border-radius: 0.9375rem;
		}
		.mb-grid-column-max-span{
			grid-column: 1 / -1;
		}
		.mb-order-1 {order: 1;}
		.mb-order-2 {order: 2;}
		.mb-order-3 {order: 3;}
		.mb-order-4 {order: 4;}
		.mb-order-5 {order: 5;}
		.mb-order-6 {order: 6;}
		.mb-order-7 {order: 7;}
		.mb-order-8 {order: 8;}
		.mb-order-9 {order: 9;}
		.mb-order-10 {order: 10;}
		.mb-min-height-205 {
			min-height: 205px;
		}
	}
</style>