<template>
	<div class="_pc-font-20 mb-font-15 mb-mar-y-20 mb-flex-row-bet-mid" style="color: #999;">
		<div>{{ $t('breadcumb') }}</div>
		<div class="_pc-display-none return-btn" @click="fanhui">
			<img style="width: 14px;height: 14px;" src="~@/assets/image/hangda/fanhui1.png" alt="">
			<span style="margin-left: 0.25rem;line-height: 1.8;">返回</span>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'breadcumb',
		data() {
			return {
				
			}
		},
		created() {

		},
		watch: {
			$route: {
				handler(to, from) {
					
				}
			}
		},
		methods: {
			fanhui(){
				this.$router.replace({
					path: '/question',
				})
			},
		}
	}
</script>

<style scoped>
	/*屏幕在480px到768之间（主要是手机屏幕）*/
	@media screen and (max-width: 768px){
		.mb-font-15 {
			font-size: 0.75rem;
		}
		.mb-mar-y-20 {
			padding-top: 0.625rem;
			padding-bottom: 0.625rem;
		}
		.mb-flex-row-bet-mid {
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
		}
		.return-btn {
			color: #fff;
			background: #4284DC;
			border-radius: 0.3rem;
			padding: 0.3125rem 0.625rem;
			display: flex;
			align-items: center;
			
		}
	}
</style>